<template>
  <div class="app-container">
    <div class="filter-container" style="display: flex;flex-wrap: wrap;">
      <el-button class="filter-item" type="primary" @click="handleCreate">新增</el-button>

      <el-input class="filter-item" v-model="listQuery.phone" placeholder="手机号码" style="width: 220px;" clearable/>

      <el-select class="filter-item" v-model="listQuery.gender" placeholder="性别" style="width: 120px" clearable>
        <el-option label="男" :value="1"/>
        <el-option label="女" :value="2"/>
      </el-select>

      <el-date-picker v-model="dateArrQuery" type="daterange" value-format="yyyy-MM-dd" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" class="filter-item" style="display: flex;" clearable></el-date-picker>

      <el-input class="filter-item" v-model="listQuery.code" placeholder="兑换码" style="width: 140px;" clearable/>

      <el-select class="filter-item" v-model="listQuery.sms_status" placeholder="发送短信状态" style="width: 140px" clearable>
        <el-option label="未发送" :value="0"/>
        <el-option label="发送成功" :value="1"/>
        <el-option label="发送失败" :value="2"/>
      </el-select>

      <el-select class="filter-item" v-model="listQuery.sms_receive_status" placeholder="短信接收状态" style="width: 140px" clearable>
        <el-option label="待接收" :value="0"/>
        <el-option label="成功" :value="1"/>
        <el-option label="失败" :value="2"/>
      </el-select>

      <el-select class="filter-item" v-model="listQuery.is_redeem" placeholder="是否兑换" style="width: 120px" clearable>
        <el-option label="未兑换" :value="0"/>
        <el-option label="已兑换" :value="1"/>
      </el-select>

      <el-button class="filter-item" type="primary" @click="handleFilter">搜索</el-button>

      <el-button class="filter-item" type="success" @click="downloadCateredStudentTemplate" style="margin-left: 0px;">包餐学生模板</el-button>

      <el-upload class="filter-item" accept=".xlsx,.xls" action="" :show-file-list="false" :auto-upload="false" :on-change="handleImportCateredStudent">
        <el-button type="warning">导入包餐学生</el-button>
      </el-upload>
    </div>

    <el-table v-loading="listLoading" :data="list" element-loading-text="Loading" fit highlight-current-row>
      <el-table-column label="ID" width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.id }}
        </template>
      </el-table-column>

      <el-table-column label="手机号码" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.phone }}
        </template>
      </el-table-column>

      <el-table-column label="性别" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.gender === 1" type="primary">男</el-tag>
          <el-tag v-else-if="scope.row.gender === 2" type="danger">女</el-tag>
        </template>
      </el-table-column>

      <el-table-column label="包餐备注名" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.catered_name }}
        </template>
      </el-table-column>

      <el-table-column label="剩余次数" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.num }}
        </template>
      </el-table-column>

      <el-table-column label="开始日期" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.start_date }}
        </template>
      </el-table-column>

      <el-table-column label="结束日期" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.end_date }}
        </template>
      </el-table-column>

      <el-table-column label="兑换码" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.code }}
        </template>
      </el-table-column>

      <el-table-column label="发送短信状态" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag type="info" v-if="scope.row.sms_status === 0">未发送</el-tag>
          <el-tag type="success" v-else-if="scope.row.sms_status === 1">发送成功</el-tag>
          <el-tag type="danger" v-else-if="scope.row.sms_status === 2">发送失败</el-tag>
        </template>
      </el-table-column>

      <el-table-column label="短信接收状态" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.sms_receive_status === 'DELIVRD'">成功</el-tag>
          <el-tag type="danger" v-else-if="scope.row.sms_receive_status">{{ scope.row.sms_receive_status }}</el-tag>
        </template>
      </el-table-column>

      <el-table-column label="是否兑换" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.catered_student">已兑换</el-tag>
          <el-tag type="info" v-else>未兑换</el-tag>
        </template>
      </el-table-column>

      <el-table-column label="兑换用户" min-width="110" align="center">
        <template slot-scope="scope">
          <div v-if="scope.row.catered_student && scope.row.catered_student.user">
            <div>{{ scope.row.catered_student.user.name }}</div>
            <div>{{ scope.row.catered_student.user.phone }}</div>
          </div>
        </template>
      </el-table-column>

      <el-table-column label="操作" width="250" align="center" fixed="right" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="handleUpdate(scope.row)">修改</el-button>

          <el-button type="danger" size="mini" @click="handleDelete(scope.row)">删除</el-button>

          <el-button type="warning" size="mini" @click="handleResendSms(scope.row)" :disabled="scope.row.sms_status !== 2 && (!scope.row.sms_receive_status || scope.row.sms_receive_status == 'DELIVRD')">重发短信</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination :current-page="listQuery.page" :page-sizes="[5,10,20,30,50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>

    <!-- 详情 -->
    <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible" width="60%" :close-on-click-modal="false">
      <el-form ref="form" :rules="rules" :model="form" label-width="150px">
        <el-form-item label="手机号码" prop="phone">
          <el-input v-model="form.phone" :disabled="!!form.id"></el-input>
        </el-form-item>

        <el-form-item label="性别" prop="gender">
          <el-select v-model="form.gender" :disabled="!!form.catered_student">
            <el-option label="男" :value="1"/>
            <el-option label="女" :value="2"/>
          </el-select>
        </el-form-item>

        <el-form-item label="包餐备注名" prop="catered_name">
          <el-input v-model="form.catered_name" :disabled="!!form.catered_student"></el-input>
        </el-form-item>

        <el-form-item label="次数" prop="num">
          <el-input-number v-model="form.num" controls-position="right" :min="0" :precision="0" :disabled="!!form.catered_student"></el-input-number>
        </el-form-item>

        <el-form-item label="有效时间">
          <el-date-picker v-model="dateArr" type="daterange" value-format="yyyy-MM-dd" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" clearable :disabled="!!form.catered_student"></el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading">确定</el-button>
      </div>
    </el-dialog>
    <!-- 详情 -->

    <!-- 导入包餐学生 -->
    <el-dialog title="导入数据预览" :visible.sync="importCateredStudentVisible" width="60%" :close-on-click-modal="false">
      <div style="height: 500px" class="scrollbar">
        <el-scrollbar style="height: 500px">
          <el-table :data="importCateredStudentData" style="width: 1100px">
            <el-table-column label="序号" min-width="60" align="center">
              <template slot-scope="scope">
                {{ scope.row.index || "" }}
              </template>
            </el-table-column>

            <el-table-column label="手机号码" min-width="60" align="center">
              <template slot-scope="scope">
                {{ scope.row.phone || "" }}
              </template>
            </el-table-column>

            <el-table-column label="性别" min-width="60" align="center">
              <template slot-scope="scope">
                {{ scope.row.gender || "" }}
              </template>
            </el-table-column>

            <el-table-column label="包餐备注名" min-width="60" align="center">
              <template slot-scope="scope">
                {{ scope.row.catered_name || "" }}
              </template>
            </el-table-column>

            <el-table-column label="次数" min-width="60" align="center">
              <template slot-scope="scope">
                {{ scope.row.num || "" }}
              </template>
            </el-table-column>

            <el-table-column label="开始日期" min-width="60" align="center">
              <template slot-scope="scope">
                {{ scope.row.start_date || "" }}
              </template>
            </el-table-column>

            <el-table-column label="结束日期" min-width="60" align="center">
              <template slot-scope="scope">
                {{ scope.row.end_date || "" }}
              </template>
            </el-table-column>
          </el-table>
        </el-scrollbar>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button @click="importCateredStudentVisible = false">取消</el-button>
        <el-button type="primary" @click="importCateredStudentSaveData" :loading="btnLoading">确定</el-button>
      </div>
    </el-dialog>
    <!-- 导入包餐学生 -->
  </div>
</template>

<script>
  import request from "@/utils/request";
  import { mapGetters } from "vuex";
  import {excel2Json} from "@/utils/excel";

  export default {
    data() {
      return {
        list: null,
        total: null,
        listLoading: true,
        listQuery: {
          page: 1,
          limit: 10,
          school_id: "",
          phone: "",
          gender: "",
          start_date: "",
          end_date: "",
          code: "",
          sms_status: "",
          sms_receive_status: "",
          is_redeem: "",
        },
        dialogFormVisible: false,
        dialogStatus: "",
        textMap: {
          update: "修改",
          create: "新增",
        },
        btnLoading: false,
        form:{
          id: "",
          school_id: "",
          phone: "",
          gender: "",
          catered_name: "",
          num: 0,
          start_date: "",
          end_date: "",
        },
        rules: {
          phone: [{ required: true, message: "手机号码不能为空", trigger: "change" }],
          gender: [{ required: true, message: "性别不能为空", trigger: "change" }],
          catered_name: [{ required: true, message: "包餐备注名不能为空", trigger: "change" }],
          num: [{ required: true, message: "次数不能为空", trigger: "change" }],
        },
        dateArr: null,
        // 导入包餐学生
        importCateredStudentData: [],
        importCateredStudentVisible: false,
        dateArrQuery: null,
      };
    },
    created() {
      this.getList();
    },
    computed: {
      ...mapGetters(["schools","school_id","user"])
    },
    watch: {
      school_id(newVal, oldVal) {
        this.listQuery.school_id = newVal;
        this.getList();
      },
      dateArr(newVal, oldVal) {
        if (newVal) {
          this.form.start_date = newVal[0];
          this.form.end_date = newVal[1];
        } else {
          this.form.start_date = "";
          this.form.end_date = "";
        }
      },
      dateArrQuery(newVal, oldVal) {
        if (newVal) {
          this.listQuery.start_date = newVal[0];
          this.listQuery.end_date = newVal[1];
        } else {
          this.listQuery.start_date = "";
          this.listQuery.end_date = "";
        }
      }
    },
    methods: {
      getList() {
        this.listQuery.school_id = this.school_id;
        this.listLoading = true;
        request({
          url: "/api/backend/importCateredStudent/list",
          method: "get",
          params: this.listQuery,
          timeout: 6000000,
        }).then(response => {
          this.list = response.data.data;
          this.total = response.data.total;
          this.listLoading = false;
        });
      },
      handleFilter() {
        this.listQuery.page = 1;
        this.getList();
      },
      handleSizeChange(val) {
        this.listQuery.limit = val;
        this.getList();
      },
      handleCurrentChange(val) {
        this.listQuery.page = val;
        this.getList();
      },
      resetForm() {
        this.form = {
          id: "",
          school_id: "",
          phone: "",
          gender: "",
          catered_name: "",
          num: 0,
          start_date: "",
          end_date: "",
        }
        this.btnLoading = false;
        this.dateArr = null;
      },
      handleCreate() {
        this.resetForm()
        this.dialogStatus = "create"
        this.dialogFormVisible = true
        this.$nextTick(() => {
          this.$refs["form"].clearValidate() //清除校验结果
        })
      },
      handleUpdate(row) {
        this.resetForm()
        this.form = Object.assign({}, row)
        this.dateArr = [this.form.start_date,this.form.end_date];
        this.dialogStatus = "update"
        this.dialogFormVisible = true
        this.$nextTick(() => {
          this.$refs["form"].clearValidate() //清除校验结果
        })
      },
      saveData() {
        this.form.school_id = this.school_id;
        if (!this.form.school_id) {
          this.$message({
            type: "warning",
            message: "请先选择学校"
          });
          return;
        }
        if (!this.form.start_date) {
          this.$message({
            type: "warning",
            message: "开始日期不能为空"
          });
          return;
        }
        if (!this.form.end_date) {
          this.$message({
            type: "warning",
            message: "结束日期不能为空"
          });
          return;
        }

        this.$refs["form"].validate((valid) => {
          if (valid) {
            this.btnLoading = true
            request({
              url: "/api/backend/importCateredStudent/save",
              method: "post",
              data: this.form
            }).then(() => {
              this.btnLoading = false
              this.dialogFormVisible = false
              this.$message({
                type: "success",
                message: "操作成功"
              });
              this.getList();
            }).catch(() => {
              this.btnLoading = false
            })
          }
        })
      },
      handleDelete(row) {
        this.$confirm("确认继续该操作", "提示", {
          type: "warning"
        }).then(() => {
          request({
            url: "/api/backend/importCateredStudent/delete",
            method: "post",
            data: {
              id: row.id
            }
          }).then(()=>{
            this.getList();
            this.list.length <= 1 && this.listQuery.page > 1 ? this.listQuery.page-- : false;
            this.$message({
              type: "success",
              message: "操作成功"
            });
          })
        }).catch(() => {});
      },
      // 包餐学生模板
      downloadCateredStudentTemplate() {
        let a = document.createElement("a");
        a.href = "/包餐学生模板.xlsx";
        a.download = "包餐学生模板.xlsx";
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
        a.remove();
      },
      // 导入包餐学生
      handleImportCateredStudent(file) {
        this.importCateredStudentData = [];
        file = file.raw;
        if (!(file.name.split(".")[1] === "xlsx" || file.name.split(".")[1] === "xls")) {
          this.$message.error("上传文件格式不正确，请检查选择的文件");
          return;
        }
        excel2Json(file, 1).then((excelData) => {
          // 处理业务
          if (excelData.length <= 0) {
            this.$message.error("数据不能为空");
            return;
          }
          if (excelData.length > 300) {
            this.$message.error(`数据批量处理限制300以内,当前数量${excelData.length}`);
            return;
          }
          excelData.forEach((element, index) => {
            this.importCateredStudentData.push({
              index: element["序号"]||"",
              phone: (element["手机号码"]||"").toString(),
              catered_name: element["包餐备注名"]||"",
              num: element["次数"]||"",
              gender: element["性别"]||"",
              start_date: element["开始日期"]||"",
              end_date: element["结束日期"]||"",
            });
          });
          this.importCateredStudentVisible = true;
        });
      },
      importCateredStudentSaveData() {
        if (!this.school_id) {
          this.$message({
            type: "warning",
            message: "请先选择学校"
          });
          return;
        }
        this.btnLoading = true;
        for (let index = 0; index < this.importCateredStudentData.length; index++) {
          const item = this.importCateredStudentData[index];
          for (var key in item) {
            if (!item[key]) {
              this.$message({
                type: "error",
                message: "请先完善数据",
              });
              this.btnLoading = false;
              return;
            }
          }
        }
        request({
          url: "/api/backend/importCateredStudent/batchSave",
          method: "post",
          data: {
            school_id: this.school_id,
            data: this.importCateredStudentData,
          },
          timeout: 10 * 60 * 1000,
        }).then(() => {
          this.btnLoading = false;
          this.importCateredStudentVisible = false;
          this.$message({
            type: "success",
            message: "操作成功",
          });
          this.getList();
        }).catch(() => {
          this.btnLoading = false;
        });
      },
      // 重发短信
      handleResendSms(row) {
        this.$confirm("确认继续该操作", "提示", {
          type: "warning"
        }).then(() => {
          request({
            url: "/api/backend/importCateredStudent/resendSms",
            method: "post",
            data: {
              id: row.id
            }
          }).then(()=>{
            this.getList();
            this.$message({
              type: "success",
              message: "操作成功"
            });
          })
        }).catch(() => {});
      },
    }
  };
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
  .el-col{
    margin-bottom: 20px;
  }
  .box-card {
    text-align: center;
    .value {
      color: #546DFE;
      font-weight: bold;
      font-size: 26px;
    }
    .subtitle {
      font-size: 12px;
      color: gray;
      margin-top: 6px;
    }
  }
</style>
